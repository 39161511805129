




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import AdoriService from '@/services/adori'
import get from 'lodash.get'
import Poll from './Poll.vue'
import errorMessages from '@/utils/errorMessages'

@Component({
  components: {
    Poll,
  },
})
export default class extends Vue {
  @Prop() episode!: any
  @Prop() showEpisodeDetails!: any
  @Prop() currentTag!: any
  @Prop() currentTagDuration!: any

  @Getter experiencesById!: any
  @Getter tagsById!: any

  showPoll = true

  get tagAction() {
    if (
      this.currentTag &&
      this.currentTagDuration &&
      ['click', 'create_note', 'call', 'buy', 'choose'].includes(this.currentTag.tag.actions)
    ) {
      return this.tagsById(this.currentTag.tag.id)
    }
    return false
  }

  get tagIcon() {
    return this.tagAction.actions === 'click'
      ? this.link
        ? 'link'
        : 'thumb_up'
      : this.tagAction.actions === 'create_note'
      ? 'message'
      : this.tagAction.actions === 'call'
      ? 'call'
      : this.tagAction.actions === 'buy'
      ? 'shopping_bag'
      : ''
  }

  get link() {
    switch (this.tagAction.actions) {
      case 'navigate':
        return `https://www.google.com/maps/place/${(
          this.tagAction.location || this.tagAction.locationInfo
        ).location.replace('geo:', '')}`
      case 'choose':
        return 'poll-init'
      case 'call':
        return `tel:${this.tagAction.contact.phoneNumber}`
      case 'click':
        return this.tagAction.url
      case 'display_ad':
        return this.tagAction.displayAd && this.tagAction.displayAd.actionUrl
      case 'buy':
        return this.tagAction.buy.actionUrl
    }
    return ''
  }

  get tagExperience() {
    return this.experiencesById(this.currentTag.id)
  }

  get likeStatus() {
    return get(this.tagExperience, 'interaction.likeStatus') ? 'red' : 'black'
  }

  handleClick() {
    switch (this.tagAction.actions) {
      case 'call':
        this.handleNavigation(this.link)
        break
      case 'click':
        this.link ? this.handleNavigation(this.link) : this.handleLike()
        break
      case 'buy':
        this.handleNavigation(this.link)
        break
    }
  }

  handleNavigation(url: string) {
    const uri = url.includes('http') || url.includes('tel') ? url : `https://${url}`
    window.open(uri, '_blank')
  }

  async handleLike() {
    try {
      let interaction = null
      if (this.likeStatus !== 'black') {
        interaction = (await AdoriService.unlikeAnExperience(this.currentTag.id)).data
      } else {
        interaction = (await AdoriService.likeAnExperiece(this.currentTag.id)).data
      }
      this.$store.dispatch('setExperience', { ...this.tagExperience, interaction })
    } catch (error) {
      console.log(error)
    }
  }

  async submitPoll(option) {
    try {
      const interaction = (await AdoriService.submitPoll(this.currentTag.id, option)).data
      this.$store.dispatch('setExperience', { ...this.tagExperience, interaction })
    } catch (error: any) {
      if (error && error.status === 409) {
        const interaction = error.data
        this.$store.dispatch('setExperience', { ...this.tagExperience, interaction })
        console.log(errorMessages.pollAlreadySubmitted)
      }
    }
  }
}
