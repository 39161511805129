









































































import { Component, Vue, Watch } from 'vue-property-decorator'
import AdoriService from '@/services/adori'
import { Action, Getter } from 'vuex-class'
import Audio from '@/components/Audio.vue'
import errorMessages from '@/utils/errorMessages'
import axios from 'axios'
import { Swiper, SwiperSlide } from 'swiper-vue2'
import get from 'lodash.get'

import 'swiper/swiper-bundle.css'
import ControlsV2 from '@/components/ControlsV2.vue'
import { selectTagPosition } from '@/utils/tag-utils'
import EpisodeMenu from '@/components/EpisodeMenu.vue'

@Component({
  components: {
    Audio,
    Swiper,
    SwiperSlide,
    ControlsV2,
    EpisodeMenu,
  },
})
export default class AdoriPlayerV2 extends Vue {
  @Getter currentTime!: any
  @Getter episode!: any
  @Getter transcript!: any
  @Getter audio!: any
  @Getter playing!: any
  @Getter srcAdded!: any

  @Action getTranscript!: Function

  isLoading = false
  isTouched = false
  expPoll: any = null
  showEpisodeDetails = false

  controlSwiper!: any
  activeIndex = 0
  isCaptionActive = false

  currentCaption: any = {}

  get tagPositions() {
    return this.$store.getters.experiences
  }

  get currentTagPosition(): any {
    if (this.currentTime !== null) {
      return selectTagPosition(this.tagPositions, this.currentTime)
    }
    return null
  }
  get currentTagDuration() {
    return (
      this.currentTime <
      (this.currentTagPosition &&
        (this.currentTagPosition.durationMillis + this.currentTagPosition.offsetMillis) / 1000)
    )
  }

  isVideo(tag: any) {
    if (get(tag, 'video.id', false)) {
      return true
    } else {
      return false
    }
  }

  getVideoSrc(tag: any) {
    return get(tag, 'video.originalUrl', '')
  }

  get currentTagIndex() {
    return this.currentTagPosition
      ? this.tagPositions.findIndex((obj: any) => obj.id === this.currentTagPosition.id)
      : 0
  }

  get episodeImage() {
    return this.episode.imageUrl ? this.episode.imageUrl : this.episode.image ? this.episode.image.urls.regular : ''
  }

  get words() {
    return get(this.transcript, 'length', 0)
      ? this.transcript.filter(
          (obj: any) => this.currentTime >= parseFloat(obj.start_time) && this.currentTime <= parseFloat(obj.end_time)
        )[0]
      : []
  }

  @Watch('srcAdded')
  handleSrc() {
    try {
      this.audio.muted = true
      this.audio.play()
      this.audio.pause()
      this.audio.muted = false
    } catch (err: any) {
      this.audio.muted = false
    }
  }

  @Watch('currentTagPosition')
  handleTagPosition() {
    if (this.currentTagPosition !== null) {
      this.controlSwiper.slideTo(this.currentTagIndex)
    }
  }

  @Watch('currentTime')
  handleCurrentTime() {
    this.activeIndex = get(this.controlSwiper, 'realIndex')
    const word = get(this.words, 'words', []).find(
      (obj: any) => this.currentTime >= parseFloat(obj.start_time) && this.currentTime <= parseFloat(obj.end_time)
    )
    word && (this.currentCaption[word.start_index] = word)
    if (Object.values(this.currentCaption).length > 13) this.currentCaption = {}
  }

  async created() {
    this.handleEpisodeUid(this.$route.params.episodeid)
    try {
      await this.getTranscript(this.$route.params.episodeid)
    } catch (error) {
      console.log(error)
    }
  }

  async handleEpisodeUid(uid) {
    try {
      this.isLoading = true
      const metaData = (await AdoriService.fetchEpisodeDetails(uid)).data
      this.$store.dispatch('setEpisode', metaData)
      this.$store.dispatch('setDuration', metaData.durationMillis && metaData.durationMillis / 1000.0)
      this.$store.dispatch('setAudioSrc', this.episode.audioUrl)
      this.handleEpisodeUrl(this.episode.audioUrl)
    } catch (error) {
      console.log(error)
      //   this.isInvalidAudio = true
      this.isLoading = false
    }
  }

  async handleEpisodeUrl(url) {
    this.$store.dispatch('setAudioSrc', url)
    let redirectedUrl = ''

    try {
      const cancelRedirect = axios.CancelToken.source()
      await axios.get(url, {
        onDownloadProgress: (progres) => {
          const currentTarget = progres.target
          if (currentTarget.readyState >= 2) {
            redirectedUrl = currentTarget.responseURL
            cancelRedirect.cancel()
          }
        },
        cancelToken: cancelRedirect.token,
      })
    } catch (error) {
      console.log(errorMessages.requestCancel)
    }

    try {
      this.$store.dispatch('setPlayerPermission', {
        key: 'allowOtherEpisodes',
        value: false,
      })
      const self = this
      this.expPoll = setInterval(
        (function poll() {
          if (redirectedUrl) {
            self.handleExpPoll(redirectedUrl)
          } else {
            clearInterval(self.expPoll)
          }
          return poll
        })(),
        10000
      )
    } catch (error) {
      this.isLoading = false
      console.log(errorMessages.redirectUrl)
    }

    // this.isLoading = false
  }

  async handleExpPoll(url, setEpi = true) {
    try {
      const res: any = await AdoriService.fetchDecodedExperiences(url)
      if (res && res.status === 200) {
        clearInterval(this.expPoll)
        this.isLoading = false
        if (!setEpi) {
          this.$store.dispatch('setEpisode', res.data.episode)
          this.$store.dispatch(
            'setDuration',
            res.data.episode.durationMillis && res.data.episode.durationMillis / 1000.0
          )
        }
        this.$store.dispatch('setDecodedExperiences', res.data.experiences)
      }
    } catch (error) {
      clearInterval(this.expPoll)
      this.isLoading = false
      //   this.isInvalidAudio = true
      console.log(errorMessages.decode)
    }
  }

  onSwiper(swiper: any) {
    this.controlSwiper = swiper
    this.activeIndex = get(this.controlSwiper, 'realIndex', 0)
  }

  slideChange(swiper: any) {
    if (this.isTouched) {
      const tag = this.tagPositions[swiper.realIndex]
      this.$store.dispatch('seekAudio', tag.offsetMillis / 1000)
      this.controlSwiper.slideTo(swiper.realIndex)
    }
    this.isTouched = false
  }

  previousSlide() {
    const tag = this.tagPositions[this.controlSwiper.realIndex - 1]
    this.$store.dispatch('seekAudio', tag.offsetMillis / 1000)
    this.controlSwiper.slideTo(this.controlSwiper.realIndex - 1)
  }

  nextSlide() {
    const tag = this.tagPositions[this.controlSwiper.realIndex + 1]
    this.$store.dispatch('seekAudio', tag.offsetMillis / 1000)
    this.controlSwiper.slideTo(this.controlSwiper.realIndex + 1)
  }

  touchEnd() {
    this.showEpisodeDetails = false
    this.isTouched = true
    setTimeout(() => {
      this.isTouched = false
    }, 500)
  }

  getImageUrl(tag: any) {
    return tag.imageInfo ? tag.imageInfo.url : tag.image ? tag.image.urls.regular : ''
  }
}
