import { render, staticRenderFns } from "./AdoriPlayerV2.vue?vue&type=template&id=39b3df70&scoped=true&"
import script from "./AdoriPlayerV2.vue?vue&type=script&lang=ts&"
export * from "./AdoriPlayerV2.vue?vue&type=script&lang=ts&"
import style0 from "./AdoriPlayerV2.vue?vue&type=style&index=0&id=39b3df70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b3df70",
  null
  
)

export default component.exports