



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { Wave } from '@foobar404/wave'

@Component({
  filters: {
    formatTime(value) {
      if (value === null) {
        return '--:--'
      }
      let intValue = Math.round(value)
      let minutes = Math.floor(intValue / 60)
      let seconds = Math.floor(intValue % 60)
      let minString = minutes.toString().padStart(2, '0')
      let secString = seconds.toString().padStart(2, '0')
      return `${minString}:${secString}`
    },
  },
})
export default class ControlsV2 extends Vue {
  @Prop() isCaptionActive!: boolean

  @Getter playing!: boolean
  @Getter srcAdded!: boolean
  @Getter audio!: any
  @Getter currentTime!: any
  @Getter duration!: any
  @Getter transcript!: any

  get isTranscriptAdded() {
    return !!this.transcript.length
  }

  get isSafari() {
    return (
      navigator.vendor.match(/apple/i) &&
      !navigator.userAgent.match(/crios/i) &&
      !navigator.userAgent.match(/fxios/i) &&
      !navigator.userAgent.match(/Opera|OPT\//)
    )
  }

  get isIos() {
    return /iPad|iPhone|iPod/.test(navigator.platform)
  }

  get seekBarTime() {
    if (this.currentTime === null || this.duration === null) {
      return 0
    }
    return (this.currentTime / this.duration) * 100
  }

  set seekBarTime(value: any) {
    let seekTime = (value * this.duration) / 100
    if (Math.abs(seekTime - this.currentTime) > 1) {
      this.$store.dispatch('setCurrentTime', seekTime)
      this.$store.dispatch('seekAudio', seekTime)
    }
  }

  forward() {
    this.$store.dispatch('seekAudio', this.currentTime + 10)
  }

  reverse() {
    this.$store.dispatch('seekAudio', this.currentTime - 10)
  }

  wave!: Wave

  mounted() {
    if (!this.isSafari && !this.isIos) {
      let canvasElement: any = document.querySelector('#canvasElmId')
      this.wave = new Wave(this.audio, canvasElement)
    }
  }

  pauseAudio() {
    this.audio.pause()
  }
  playAudio() {
    if (this.srcAdded) {
      this.audio.play().catch((reason) => {
        console.error('Cannot play audio: ', reason)
        console.log(reason.message)
      })
    }

    if (!this.isSafari && !this.isIos) {
      this.wave.addAnimation(
        new this.wave.animations.Wave({
          lineColor: '#273B4A',
          lineWidth: 1.5,
          fillColor: {
            gradient: ['rgba(0,0,255,0.2)', 'rgba(255,0,85,0.2)'],
          },
          mirroredX: true,
          count: 25,
          glow: { strength: 1, color: '#FAD961' },
          rounded: true,
          frequencyBand: 'base',
        })
      )
    }
  }

  beforeDestroy() {
    this.wave.clearAnimations()
  }
}
